import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import SecondaryNav from '../components/secondarynav'
import BorderedBox from '../components/borderedbox'

import headerimage from '../assets/images/whoweare/Corprate_WhoWeAre_IF_header_03.jpg'
import mbheaderimage from '../assets/images/whoweare/Corprate_WhoWeAre_IF_mbheader.jpg'
import tileimage1 from '../assets/images/whoweare/Corprate_WhoWeAre_InternationalFranchises_01.jpg'
import tileimage2 from '../assets/images/whoweare/Corprate_WhoWeAre_InternationalFranchises_02.jpg'
import tileimage3 from '../assets/images/whoweare/Corprate_WhoWeAre_InternationalFranchises_03.jpg'
import { trackPageData, getPageDetails } from '../analytics'

class InternationalFranchises extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="International Franchises | The Children's Place Corporate Website"
          description="Want to partner with The Children's Place? Find out more about The Children's Place international franchises and how to apply."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-3">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="WHO WE ARE"
            />
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="International Franchises"
          name1="About Us"
          link1="/about-us"
          name2="Senior Leadership"
          link2="/senior-leadership"
          name3="Board of Directors"
          link3="/board-of-directors"
          name4="International Franchises"
          link4="/international-franchises"
        />

        {/* <div class="row mb-5 text-center">
          <div class="col-12">
            <h2>INTERNATIONAL EXPANSION</h2>
          </div>
        </div> */}

        <div class="row mb-5 no-gutters">
          <div class="col-12 text-center">
            <BorderedBox classname="mb-n-border">
              <h2>WANT TO PARTNER WITH US?</h2>
              <p>
                Potential partners must have extensive business experience in
                the apparel market and extensive knowledge in brand management,
                franchise business development, merchandising, real estate,
                retail store operations and digital e-commerce website
                platforms.
              </p>
            </BorderedBox>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-lg-6 col-md-6 col-12 text-center">
            <h2>CONTACT US</h2>
            <p class="text-large">
              Prospective partners should submit the following documents via
              email for consideration to:{' '}
              <a href="mailto:strategic_partnerships@childrensplace.com">
                strategic_partnerships@childrensplace.com
              </a>
            </p>
            <hr class="d-md-none border-top" />
          </div>
          <div class="col-lg-6 col-md-6 col-12 border-left">
            <p>
              <strong>Required Documentation:</strong>
            </p>
            <ul class="list-bullet">
              <li>Business capabilities and examples of experience</li>
              <li>Business plan (high level)</li>
              <li>
                Current business operations (brands and points of distribution)
              </li>
              <li>Profile of your organization</li>
            </ul>
          </div>
        </div>

        <div class="row mb-5 text-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p>
              The Gymboree, Sugar &amp; Jade and PJ Place brands are not
              available for franchise opportunities at this time.
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <img src={tileimage1} className="img-fluid mb-1" alt="" />
            <p>Sambil Curacao, Willemstad, Curacao</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <img src={tileimage2} className="img-fluid mb-1" alt="" />
            <p>Dubai Mall, Dubai, UAE</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <img src={tileimage3} className="img-fluid mb-1" alt="" />
            <p>Plaza Indonesia, Jakarta, Indonesia</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default InternationalFranchises
